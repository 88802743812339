import React from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';
import { css, cx } from 'emotion';
import { stylesFactory, Button } from '@grafana/ui';
import { unparse } from "papaparse";

interface Props extends PanelProps<SimpleOptions> {}

export const SimplePanel: React.FC<Props> = ({ options, data, width, height }) => {
  const styles = getStyles();

  // @ts-ignore
  window.ds = data;

  return (
    <div
      className={cx(
        styles.wrapper,
        css`
          width: ${width}px;
          height: ${height}px;
        `
      )}
    >
      <div className={styles.container}>
        {data.series.map((s, index) => <Button className={styles.button} key={index} onClick={() => {
          const name = s.refId || "Unnamed";

          const rows: string[][] = [
              s.fields.map(f => f.name)
          ];

          for(let i = 0; i < s.fields[0].values.length; i++) {
            rows.push(
                s.fields.map(f => f.display ? f.display(f.values.get(i)).text : coalesce(f.values.get(i), "").toString())
            );
          }

          const csv = unparse(rows);
          let fileName = name.replace(/[^A-z0-9-_]+/g, "-").replace(/--/g, "-") + '.csv'
          let jsonBlob = new Blob([csv])
          downloadBlob(jsonBlob, fileName);
        }}>Download {s.refId}</Button>)}
      </div>
    </div>
  );
};

function coalesce(input: any, defaultValue: string) {
  if(input === undefined || input === null) {return defaultValue;}
  return input;
}

function downloadBlob(blob: Blob, name = 'file.txt') {
  // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
  const blobUrl = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement("a");

  // Set link's href to point to the Blob URL
  link.href = blobUrl;
  link.download = name;
  link.target = "_blank"

  // Append link to the body
  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
  );

  // Remove link from body
  document.body.removeChild(link);
  URL.revokeObjectURL(blobUrl);
}

const getStyles = stylesFactory(() => {
  return {
    wrapper: css`
      position: relative;
    `,
    container: css`
      display: flex;
    `,
    button: css`
      margin-right: 8px;
    `
  };
});
